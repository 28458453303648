import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  VStack,
  HStack,
  IconButton,
  Box,
  Flex,
  Text,
  FormErrorMessage,
  Divider,
  SimpleGrid,
  Heading,
  useColorModeValue,
  useBreakpointValue,
  chakra,
} from '@chakra-ui/react';
import { MdAdd, MdDelete, MdUpload } from 'react-icons/md';
import { AuthContext } from 'contexts/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'antd/dist/reset.css';

// Configurez dayjs pour utiliser le français
dayjs.locale('fr');

// Composants séparés pour la réutilisabilité et la clarté
const FormSection = ({ title, children }) => {
  const sectionBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const titleColor = useColorModeValue("gray.700", "white");

  return (
    <Box 
      bg={sectionBg}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
      overflow="hidden"
    >
      <Box 
        p={4} 
        borderBottomWidth="1px" 
        borderColor={borderColor}
        bg={useColorModeValue("gray.50", "gray.900")}
      >
        <Text 
          fontSize="md"
          fontWeight="semibold"
          color={titleColor}
          textTransform="uppercase"
          letterSpacing="wide"
        >
          {title}
        </Text>
      </Box>
      <Box p={6}>
        {children}
      </Box>
    </Box>
  );
};

const StyledInput = ({ label, error, ...props }) => {
  const inputRef = useRef(null);
  const inputBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const handleFocus = () => {
    if (inputRef.current) {
      const modalBody = document.querySelector('.chakra-modal__body');
      if (modalBody) {
        const inputRect = inputRef.current.getBoundingClientRect();
        const modalRect = modalBody.getBoundingClientRect();
        const scrollTarget = modalBody.scrollTop + (inputRect.top - modalRect.top) - (modalRect.height / 2) + (inputRect.height / 2);
        
        modalBody.scrollTo({
          top: scrollTarget,
          behavior: 'smooth'
        });
      }
      inputRef.current.focus();
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Input
        ref={inputRef}
        onFocus={handleFocus}
        bg={inputBg}
        borderColor={borderColor}
        _hover={{
          borderColor: borderColor,
          transform: "translateY(-1px)",
          boxShadow: "sm"
        }}
        transition="all 0.2s"
        {...props}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

const StyledSelect = ({ label, error, children, ...props }) => {
  const selectRef = useRef(null);
  const selectBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const handleFocus = () => {
    if (selectRef.current) {
      const modalBody = document.querySelector('.chakra-modal__body');
      if (modalBody) {
        const selectRect = selectRef.current.getBoundingClientRect();
        const modalRect = modalBody.getBoundingClientRect();
        const scrollTarget = modalBody.scrollTop + (selectRect.top - modalRect.top) - (selectRect.height / 2) + (selectRect.height / 2);
        
        modalBody.scrollTo({
          top: scrollTarget,
          behavior: 'smooth'
        });
      }
      selectRef.current.focus();
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Select
        ref={selectRef}
        onFocus={handleFocus}
        bg={selectBg}
        borderColor={borderColor}
        _hover={{
          borderColor: borderColor,
          transform: "translateY(-1px)",
          boxShadow: "sm"
        }}
        transition="all 0.2s"
        {...props}
      >
        {children}
      </Select>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

const StyledDateInput = ({ label, value, onChange, error, ...props }) => {
  const inputRef = useRef(null);
  const inputBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    // Met à jour l'affichage quand la valeur externe change
    if (value) {
      try {
        // Essaie de parser la date dans différents formats
        let date;
        if (value.includes('T')) {
          // Format ISO
          date = new Date(value);
        } else {
          // Format YYYY-MM-DD
          const [year, month, day] = value.split('-');
          date = new Date(year, month - 1, day);
        }

        if (!isNaN(date.getTime())) {
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          setInputValue(`${day}/${month}/${year}`);
        } else {
          setInputValue('');
        }
      } catch (error) {
        console.error('Erreur de parsing de date:', error);
        setInputValue('');
      }
    } else {
      setInputValue('');
    }
  }, [value]);

  const handleChange = (e) => {
    let newValue = e.target.value;
    
    // Permet seulement les chiffres et les /
    newValue = newValue.replace(/[^\d/]/g, '');
    
    // Ajoute automatiquement les /
    if (newValue.length === 2 && inputValue.length === 1) newValue += '/';
    if (newValue.length === 5 && inputValue.length === 4) newValue += '/';
    
    setInputValue(newValue);
    
    // Si on a une date complète, on la convertit en format ISO
    if (newValue.length === 10) {
      const [day, month, year] = newValue.split('/');
      const date = new Date(year, month - 1, day);
      if (!isNaN(date.getTime())) {
        onChange(`${year}-${month}-${day}`);
      } else {
        onChange('');
      }
    } else {
      onChange('');
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Input
        ref={inputRef}
        maxLength={10}
        placeholder="JJ/MM/AAAA"
        value={inputValue}
        onChange={handleChange}
        bg={inputBg}
        borderColor={borderColor}
        _hover={{
          borderColor: borderColor,
          transform: "translateY(-1px)",
          boxShadow: "sm"
        }}
        transition="all 0.2s"
        {...props}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

const FileUploadSection = ({ selectedFiles, onFileChange, onRemoveFile, fileInputRef }) => {
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const fileBg = useColorModeValue("white", "gray.800");

  return (
    <FormSection title="Pièces justificatives">
      <VStack spacing={4} align="stretch">
        <HStack>
          <Button
            leftIcon={<MdUpload />}
            onClick={() => fileInputRef.current?.click()}
            colorScheme="blue"
            variant="outline"
            size="sm"
          >
            Ajouter des fichiers
          </Button>
          <input
            type="file"
            multiple
            onChange={onFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
            accept="application/pdf,image/*"
          />
        </HStack>
        {selectedFiles.length > 0 && (
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3}>
            {selectedFiles.map((file, index) => (
              <Flex
                key={index}
                bg={fileBg}
                p={3}
                borderRadius="md"
                borderWidth="1px"
                borderColor={borderColor}
                align="center"
                justify="space-between"
                transition="all 0.2s"
                _hover={{ transform: "translateY(-2px)", shadow: "sm" }}
              >
                <Text fontSize="sm" color={textColor} noOfLines={1}>
                  {file.name}
                </Text>
                <IconButton
                  size="sm"
                  icon={<MdDelete />}
                  onClick={() => onRemoveFile(index)}
                  variant="ghost"
                  colorScheme="red"
                  aria-label="Supprimer le fichier"
                />
              </Flex>
            ))}
          </SimpleGrid>
        )}
      </VStack>
    </FormSection>
  );
};

const CustomModalBody = chakra(ModalBody, {
  baseStyle: {
    "&::-webkit-scrollbar": {
      width: "12px",
      borderRadius: "10px",
      backgroundColor: `rgba(0, 0, 0, 0.05)`,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: `rgba(0, 0, 0, 0.1)`,
      minHeight: "50px",
      border: "3px solid transparent",
      backgroundClip: "content-box",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: `rgba(0, 0, 0, 0.2)`,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "transparent",
    },
    scrollbarWidth: "thin",
    scrollbarColor: `rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.05)`,
  },
});

const AddMemberModal = ({ isOpen, onClose, onSave, selectedMember }) => {
  const initialMemberState = {
    mrName: '',
    mrsName: '',
    socialSecurityNumber: '',
    socialSecurityNumberLine2: '',
    birthDate: '',
    birthDateLine2: '',
    address: '',
    phone: '',
    email: '',
    nationality: '',
    burialSite: '',
    maritalStatus: '',
    contributionStatus: '',
    children: [{ name: '', birthDate: '' }],
    delegateName: '',
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [delegates, setDelegates] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();
  const childBoxBg = useColorModeValue("white", "gray.800");
  const childBoxBorderColor = useColorModeValue("gray.200", "gray.600");

  const schema = yup.object().shape({
    mrName: yup.string(),
    mrsName: yup.string(),
    socialSecurityNumber: yup.string(),
    socialSecurityNumberLine2: yup.string(),
    birthDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    birthDateLine2: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    phone: yup.string(),
    email: yup.string().email('Email invalide'),
    nationality: yup.string(),
    burialSite: yup.string(),
    maritalStatus: yup.string(),
    contributionStatus: yup.string(),
    children: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        birthDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
      })
    ),
    delegateName: yup.string(),
  });

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialMemberState,
  });

  const children = watch('children');

  useEffect(() => {
    if (selectedMember) {
      reset(selectedMember);
    }
  }, [selectedMember, reset]);

  useEffect(() => {
    const fetchDelegates = async () => {
      try {
        const response = await fetch('https://app.falconmarketing.fr:3004/delegates');
        if (response.ok) {
          const data = await response.json();
          setDelegates(data.delegateNames);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des délégués:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de charger la liste des délégués',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
  
    fetchDelegates();
  }, [toast]);

  const handleAddChild = () => {
    setValue('children', [...children, { name: '', birthDate: '' }]);
  };

  const handleRemoveChild = (index) => {
    setValue('children', children.filter((_, i) => i !== index));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
    e.target.value = '';
  };

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  const calculateContributionAmount = (maritalStatus) => {
    switch (maritalStatus) {
      case 'married':
      case 'divorcedWithChildren':
        return 110;
      case 'single':
      case 'widowed':
      case 'divorcedWithoutChildren':
        return 55;
      default:
        return 0;
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const method = data.id ? 'PUT' : 'POST';
      const adherentUrl = `https://app.falconmarketing.fr:3004/adherents${data.id ? `/${data.id}` : ''}`;
      const contributionAmount = calculateContributionAmount(data.maritalStatus);
    
      // Sauvegardez d'abord l'adhérent
      const adherentResponse = await fetch(adherentUrl, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          cotisation: contributionAmount,
          children: undefined,
        }),
      });
    
      if (!adherentResponse.ok) {
        throw new Error(await adherentResponse.text());
      }
    
      const adherentData = await adherentResponse.json();
      const adherentId = data.id ? data.id : adherentData.adherentId;
    
      // Ajoutez les enfants
      if (!data.id || data.children.length > 0) {
        for (let child of data.children) {
          if (child.name || child.birthDate) {
            await fetch(`https://app.falconmarketing.fr:3004/children/${adherentId}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(child),
            });
          }
        }
      }
    
      // Envoyez les fichiers
      if (selectedFiles.length > 0) {
        const formData = new FormData();
        selectedFiles.forEach((file) => {
          formData.append('files', file);
        });
        
        const fileResponse = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherentId}/files`, {
          method: 'POST',
          body: formData,
        });
    
        if (!fileResponse.ok) {
          throw new Error(await fileResponse.text());
        }
      }
    
      toast({
        title: data.id ? 'Membre mis à jour' : 'Membre ajouté',
        description: `Les informations du membre ont été ${data.id ? 'mises à jour' : 'ajoutées'} avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    
      onClose();
      reset(initialMemberState);
      setSelectedFiles([]);
      if (onSave) onSave();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur s'est produite lors de ${data.id ? "la mise à jour" : "l'ajout"} du membre: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const maritalStatus = watch('maritalStatus');
    if (maritalStatus) {
      const amount = calculateContributionAmount(maritalStatus);
      setValue('cotisation', amount);
    }
  }, [watch('maritalStatus'), setValue]);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size={isMobile ? "full" : "6xl"}
      motionPreset={isMobile ? "slideInBottom" : "scale"}
    >
      <ModalOverlay backdropFilter="blur(5px)" bg="blackAlpha.300" />
      <ModalContent 
        margin={isMobile ? 0 : "3.75rem auto"}
        bg={useColorModeValue("gray.50", "gray.900")}
        borderRadius={isMobile ? "none" : "2xl"}
        overflow="hidden"
      >
        <ModalHeader
          bg={useColorModeValue("white", "gray.800")}
          borderBottom="1px"
          borderColor={useColorModeValue("gray.100", "gray.700")}
          p={6}
        >
          <Text
            fontSize={isMobile ? "xl" : "2xl"}
            fontWeight="bold"
            color={useColorModeValue("gray.900", "white")}
          >
            {selectedMember ? "Modifier un membre" : "Ajouter un nouveau membre"}
          </Text>
        </ModalHeader>

        <ModalCloseButton 
          size={isMobile ? "lg" : "md"}
          top={6}
          right={6}
        />

        <CustomModalBody 
          p={6}
          maxH="calc(100vh - 200px)"
          overflowY="auto"
          css={{
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              width: "12px",
              borderRadius: "10px",
              backgroundColor: useColorModeValue("rgba(0, 0, 0, 0.05)", "rgba(255, 255, 255, 0.05)"),
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: useColorModeValue("rgba(0, 0, 0, 0.1)", "rgba(255, 255, 255, 0.1)"),
              minHeight: "50px",
              border: "3px solid transparent",
              backgroundClip: "content-box",
              transition: "all 0.2s ease-in-out",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: useColorModeValue("rgba(0, 0, 0, 0.2)", "rgba(255, 255, 255, 0.2)"),
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
              backgroundColor: "transparent",
            },
            scrollbarWidth: "thin",
            scrollbarColor: useColorModeValue(
              "rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.05)",
              "rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.05)"
            ),
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <VStack spacing={6} align="stretch">
              <FormSection title="Informations Principales">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Controller
                    name="mrName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledInput
                        label="Nom et Prénom (Adhérent)"
                        placeholder="Nom et prénom"
                        error={error}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="mrsName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledInput
                        label="Nom et Prénom (Conjoint)"
                        placeholder="Nom et prénom"
                        error={error}
                        {...field}
                      />
                    )}
                  />
                </SimpleGrid>
              </FormSection>

              <FormSection title="Sécurité Sociale">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Controller
                    name="socialSecurityNumber"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledInput
                        label="N° Sécurité Sociale (Adhérent)"
                        placeholder="Numéro de sécurité sociale"
                        error={error}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="socialSecurityNumberLine2"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledInput
                        label="N° Sécurité Sociale (Conjoint)"
                        placeholder="Numéro de sécurité sociale"
                        error={error}
                        {...field}
                      />
                    )}
                  />
                </SimpleGrid>
              </FormSection>

              <FormSection title="Dates de Naissance">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Controller
                    name="birthDate"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <StyledDateInput
                        label="Date de naissance"
                        value={value}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="birthDateLine2"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <StyledDateInput
                        label="Date de naissance (conjoint)"
                        value={value}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </SimpleGrid>
              </FormSection>

              <FormSection title="Coordonnées">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledInput
                        label="Adresse"
                        placeholder="Adresse complète"
                        error={error}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledInput
                        label="Téléphone"
                        placeholder="Numéro de téléphone"
                        error={error}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledInput
                        label="Email"
                        placeholder="Adresse email"
                        error={error}
                        {...field}
                      />
                    )}
                  />
                </SimpleGrid>
              </FormSection>

              <FormSection title="Informations Complémentaires">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Controller
                    name="nationality"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledInput
                        label="Nationalité"
                        placeholder="Nationalité"
                        error={error}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="burialSite"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledInput
                        label="Lieu d'inhumation"
                        placeholder="Lieu d'inhumation"
                        error={error}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="maritalStatus"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledSelect
                        label="Situation familiale"
                        placeholder="Sélectionnez la situation familiale"
                        error={error}
                        {...field}
                      >
                        <option value="married">Marié</option>
                        <option value="single">Célibataire</option>
                        <option value="divorcedWithChildren">Divorcé avec enfants</option>
                        <option value="divorcedWithoutChildren">Divorcé sans enfants</option>
                        <option value="widowed">Veuf/Veuve</option>
                      </StyledSelect>
                    )}
                  />
                </SimpleGrid>
              </FormSection>

              <FormSection title="Délégué">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Controller
                    name="delegateName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledSelect
                        label="Nom du délégué"
                        placeholder="Sélectionnez un délégué"
                        error={error}
                        {...field}
                      >
                        {delegates
                          .slice()
                          .sort((a, b) => a.localeCompare(b, 'fr', { sensitivity: 'base' }))
                          .map((delegate, index) => (
                            <option key={index} value={delegate}>
                              {delegate}
                            </option>
                          ))}
                      </StyledSelect>
                    )}
                  />
                </SimpleGrid>
              </FormSection>

              <FormSection title="Enfants">
                <VStack spacing={4} align="stretch">
                  <Controller
                    name="children"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FormControl isInvalid={!!error}>
                        <FormLabel>Enfants</FormLabel>
                        <VStack spacing={6} align="stretch">
                          {children.map((child, index) => (
                            <Box
                              key={index}
                              p={4}
                              bg={childBoxBg}
                              borderRadius="md"
                              borderWidth="1px"
                              borderColor={childBoxBorderColor}
                              position="relative"
                            >
                              <VStack spacing={4} align="stretch">
                                <Controller
                                  name={`children.${index}.name`}
                                  control={control}
                                  render={({ field, fieldState: { error } }) => (
                                    <StyledInput
                                      label="Nom et Prénom"
                                      placeholder="Nom et prénom de l'enfant"
                                      error={error}
                                      {...field}
                                    />
                                  )}
                                />
                                <Controller
                                  name={`children.${index}.birthDate`}
                                  control={control}
                                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <StyledDateInput
                                      label="Date de naissance"
                                      value={value}
                                      onChange={onChange}
                                      error={error}
                                    />
                                  )}
                                />
                              </VStack>
                              <IconButton
                                aria-label="Supprimer l'enfant"
                                icon={<MdDelete />}
                                onClick={() => handleRemoveChild(index)}
                                colorScheme="red"
                                variant="ghost"
                                position="absolute"
                                top={2}
                                right={2}
                              />
                            </Box>
                          ))}
                          <Button
                            leftIcon={<MdAdd />}
                            onClick={handleAddChild}
                            colorScheme="blue"
                            variant="outline"
                            size="sm"
                            alignSelf="flex-start"
                          >
                            Ajouter un enfant
                          </Button>
                        </VStack>
                      </FormControl>
                    )}
                  />
                </VStack>
              </FormSection>

              <FormSection title="Statut de cotisation">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Controller
                    name="contributionStatus"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <StyledSelect
                        label="Statut de cotisation"
                        placeholder="Sélectionnez le statut de cotisation"
                        error={error}
                        {...field}
                      >
                        <option value="À jour">À jour</option>
                        <option value="En attente">En attente</option>
                        <option value="En retard N-1">En retard N-1</option>
                        <option value="En retard N-2">En retard N-2</option>
                        <option value="En retard N-3">En retard N-3</option>
                        <option value="En retard +N-3">En retard +N-3</option>
                        <option value="Radié">Radié</option>
                      </StyledSelect>
                    )}
                  />
                </SimpleGrid>
              </FormSection>

              <FileUploadSection
                selectedFiles={selectedFiles}
                onFileChange={handleFileChange}
                onRemoveFile={handleRemoveFile}
                fileInputRef={fileInputRef}
              />
            </VStack>
          </form>
        </CustomModalBody>

        <ModalFooter 
          borderTop="1px" 
          borderColor={useColorModeValue("gray.100", "gray.700")}
          p={4}
          bg={useColorModeValue("white", "gray.800")}
        >
          <HStack spacing={3} width={isMobile ? "full" : "auto"}>
            <Button
              colorScheme="blue"
              onClick={handleSubmit(onSubmit)}
              size={isMobile ? "lg" : "md"}
              flex={isMobile ? 1 : "auto"}
              isLoading={isSubmitting}
            >
              {selectedMember ? "Modifier" : "Ajouter"}
            </Button>
            <Button
              onClick={onClose}
              size={isMobile ? "lg" : "md"}
              flex={isMobile ? 1 : "auto"}
              variant="ghost"
            >
              Annuler
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddMemberModal;