import React, { useContext, useEffect, useState, useCallback, useMemo, useRef } from "react";
import {
  Box,
  useColorMode,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  Flex,
  useDisclosure,
  IconButton,
  Heading,
  Select,
  HStack,
  Tooltip,
  Avatar,
  SimpleGrid,
  Stack,
  Checkbox,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  InputGroup,
  InputLeftElement,
  Input,
  Badge,
  ScaleFade,
  SlideFade,
  Fade,
  Collapse,
  keyframes,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  InputRightAddon,
  Icon,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import { MdAdd, MdRemoveRedEye, MdEdit, MdDelete, MdSearch, MdAttachMoney, MdCreditCard, MdPayment } from "react-icons/md";
import AddMemberModal from "./AddMemberModal";
import AdherentDetailsModal from "./AdherentDetailsModal";
import EditMemberModal from "./EditMemberModal";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

// Import libraries for export
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import { isEqual } from "lodash";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const scaleIn = keyframes`
  from { transform: scale(0.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`;

const breathe = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
`;

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0px); }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(49, 151, 149, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(49, 151, 149, 0); }
  100% { box-shadow: 0 0 0 0 rgba(49, 151, 149, 0); }
`;

const popIn = keyframes`
  0% { 
    opacity: 0;
    transform: scale(0.8) translateY(-10px);
  }
  70% {
    transform: scale(1.05) translateY(2px);
  }
  100% { 
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

const slideInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInScale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  60% {
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const ripple = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(49, 151, 149, 0.2); }
  70% { box-shadow: 0 0 0 10px rgba(49, 151, 149, 0); }
  100% { box-shadow: 0 0 0 0 rgba(49, 151, 149, 0); }
`;

const progressBar = keyframes`
  0% { width: 0%; }
  100% { width: 100%; }
`;

const shine = keyframes`
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
`;

// Créer des versions animées des composants Chakra
const AnimatedBox = chakra(Box, {
  baseStyle: {
    animation: `${scaleIn} 0.3s ease-out`
  }
});

const AnimatedFlex = chakra(Flex, {
  baseStyle: {
    animation: `${fadeIn} 0.3s ease-out`
  }
});

const AnimatedBadge = chakra(Badge, {
  baseStyle: {
    animation: `${slideIn} 0.3s ease-out`
  }
});

const AnimatedTr = chakra(Tr, {
  baseStyle: (props) => ({
    opacity: 0,
    animation: `${popIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
    animationDelay: props.delay,
  }),
});

const AnimatedTbody = chakra(Tbody, {
  baseStyle: {
    position: "relative",
  },
});

const TableContainer = React.memo(({ children, isLoading }) => {
  return (
    <Box
      position="relative"
      sx={{
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "2px",
          bg: "teal.500",
          transform: "scaleX(0)",
          transformOrigin: "left",
          transition: "transform 0.3s ease-out",
          opacity: isLoading ? 1 : 0,
          animation: isLoading ? `${progressBar} 2s infinite` : "none",
        },
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent)",
          backgroundSize: "200% 100%",
          opacity: isLoading ? 0.5 : 0,
          transition: "opacity 0.3s ease-in-out",
          pointerEvents: "none",
          animation: isLoading ? `${shine} 1.5s infinite linear` : "none",
        }
      }}
    >
      {children}
    </Box>
  );
});

const MemberList = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const searchTimeoutRef = useRef(null);
  const { saveMemberToFirestore, getUserData } = useContext(AuthContext);
  const [members, setMembers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAdherent, setSelectedAdherent] = useState(null);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const tableBgColor = useColorModeValue("white", "gray.700");
  const tableHeaderBgColor = useColorModeValue("gray.50", "gray.700");
  const tableRowHoverBg = useColorModeValue("gray.50", "gray.600");
  const inputBgColor = useColorModeValue("white", "gray.700");
  const statusColorMapping = {
    "À jour": {
      bg: useColorModeValue("green.50", "green.900"),
      color: useColorModeValue("green.700", "green.100"),
      icon: "✓",
      hoverBg: useColorModeValue("green.100", "green.800")
    },
    "Radié": {
      bg: useColorModeValue("red.50", "red.900"),
      color: useColorModeValue("red.700", "red.100"),
      icon: "✕",
      hoverBg: useColorModeValue("red.100", "red.800")
    },
    "En retard": {
      bg: useColorModeValue("orange.50", "orange.900"),
      color: useColorModeValue("orange.700", "orange.100"),
      icon: "!",
      hoverBg: useColorModeValue("orange.100", "orange.800")
    }
  };
  const { colorMode } = useColorMode();
  const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [membersPerPage, setMembersPerPage] = useState(10);
  const [selectedDelegate, setSelectedDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [selectedContributionStatus, setSelectedContributionStatus] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "descending" });
  const [loadingMembers, setLoadingMembers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();
  const { user } = useContext(AuthContext);

  // Utilisation de useBreakpointValue pour la gestion du responsive
  const isMobile = useBreakpointValue({ base: true, md: false });
  const displayMode = useBreakpointValue({ base: "mobile", md: "desktop" });

  // Add new state for export loading
  const [isExporting, setIsExporting] = useState(false);

  const fetchDelegates = useCallback(async () => {
    try {
      const response = await fetch("https://app.falconmarketing.fr:3004/delegates");
      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }
      const data = await response.json();
      if (Array.isArray(data.delegateNames)) {
      setDelegates(data.delegateNames);
      } else {
        console.error("Format de données invalide pour les délégués:", data);
        setDelegates([]);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des délégués:", error);
      toast({
        title: "Erreur",
        description: "Impossible de charger la liste des délégués.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setDelegates([]);
    }
  }, [toast]);

  const fetchMembers = useCallback(async () => {
    try {
      const response = await fetch("https://app.falconmarketing.fr:3004/adherents");
      const data = await response.json();
      setMembers(data.adherents);
    } catch (error) {
      console.error("Erreur lors de la récupération des adhérents:", error);
      toast({
        title: "Erreur",
        description: "Impossible de charger les adhérents.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchDelegates();
    fetchMembers();
    const intervalId = setInterval(() => {
      fetchMembers();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [fetchDelegates, fetchMembers]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setCurrentPage(1);
    
    // Gérer l'état de recherche
    setIsSearching(true);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    searchTimeoutRef.current = setTimeout(() => {
      setIsSearching(false);
    }, 500);
  };

  // Nettoyer le timeout lors du démontage
  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  const handleOpenModal = useCallback(
    (adherent) => {
      setSelectedAdherent(adherent);
      onOpen();
    },
    [onOpen]
  );

  const handleSaveMember = async (memberData) => {
    await saveMemberToFirestore(memberData);
    setSelectedAdherent(null);
    handleCloseAddMemberModal();
    fetchMembers();
  };

  const handleOpenAddMemberModal = () => setIsAddMemberModalOpen(true);
  const handleCloseAddMemberModal = () => setIsAddMemberModalOpen(false);

  const handleOpenEditMemberModal = useCallback((adherent) => {
    setSelectedAdherent(adherent);
    setIsEditMemberModalOpen(true);
  }, []);

  const updateMemberStatus = async (memberId, newStatus) => {
    if (!user || !user.profileData) {
      throw new Error("Utilisateur non connecté");
    }

    const response = await fetch(
      `https://app.falconmarketing.fr:3004/adherents/${memberId}/status`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: newStatus,
          responsableId: user.uid,
          responsableNom: user.profileData.nom || '',
          responsablePrenom: user.profileData.prenom || ''
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la mise à jour du statut de l'adhérent");
    }

    return response.json();
  };

  const handleCheckboxChange = async (memberId, isChecked) => {
    if (!user || !user.profileData) {
      toast({
        title: "Erreur",
        description: "Vous devez être connecté pour effectuer cette action.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    console.log("Informations du responsable:", {
      id: user.uid,
      nom: user.profileData.nom,
      prenom: user.profileData.prenom
    });

    setLoadingMembers(prev => ({ ...prev, [memberId]: true }));
    try {
      const newStatus = isChecked ? "À jour" : "En retard";
      const currentYear = new Date().getFullYear();
      
      const response = await fetch(
        `https://app.falconmarketing.fr:3004/adherents/${memberId}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: newStatus,
            responsableId: user.uid,
            responsableNom: user.profileData?.nom || '',
            responsablePrenom: user.profileData?.prenom || '',
            lastPaymentYear: isChecked ? currentYear : currentYear - 1
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toast({
        title: "Succès",
        description: "Statut de cotisation mis à jour.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchMembers();
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut:", error);
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour le statut.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingMembers(prev => ({ ...prev, [memberId]: false }));
    }
  };

  const getStatusColor = (status) => {
    return statusColorMapping[status] || bgColor;
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const filteredMembers = useMemo(() => {
    let filtered = [...members];

    // Filtrage par recherche
    if (searchValue) {
      const searchLower = searchValue.toLowerCase();
      filtered = filtered.filter(member => 
        member.mrName?.toLowerCase().includes(searchLower) ||
        member.delegateName?.toLowerCase().includes(searchLower) ||
        member.id?.toString().includes(searchLower)
      );
    }

    // Filtrage par délégué
    if (selectedDelegate) {
      filtered = filtered.filter(member => member.delegateName === selectedDelegate);
    }

    // Filtrage par statut de contribution
    if (selectedContributionStatus) {
      filtered = filtered.filter(member => {
        const detailedStatus = calculateDetailedStatus(member.contributionStatus, member.lastPaymentYear);
        return detailedStatus === selectedContributionStatus;
      });
    }

    return filtered;
  }, [members, searchValue, selectedDelegate, selectedContributionStatus]);

  const sortedMembers = useMemo(() => {
    const sorted = [...filteredMembers];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        let aValue = a[sortConfig.key] || '';
        let bValue = b[sortConfig.key] || '';
        
        // Conversion en nombre pour l'ID
        if (sortConfig.key === 'id') {
          aValue = parseInt(aValue);
          bValue = parseInt(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [filteredMembers, sortConfig]);

  // Calcul des membres à afficher sur la page courante
  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;
  const currentMembers = sortedMembers.slice(indexOfFirstMember, indexOfLastMember);
  const totalPages = Math.ceil(sortedMembers.length / membersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber); 

  // Add memoized values for performance
  const delegateMembers = useMemo(() => 
    filteredMembers.filter((member) => member.delegateName === selectedDelegate),
    [filteredMembers, selectedDelegate]
  );

  const totalDelegateCotisation = useMemo(() => 
    delegateMembers.reduce((sum, member) => sum + (parseFloat(member.cotisation) || 0), 0),
    [delegateMembers]
  );

  // Enhanced export function
  const exportToExcel = async () => {
    setIsExporting(true);
    try {
      if (!selectedDelegate) {
        toast({
          title: "Information",
          description: "Veuillez sélectionner un délégué pour exporter sa liste.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (delegateMembers.length === 0) {
        toast({
          title: "Information",
          description: "Aucun adhérent trouvé pour ce délégué.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Enhanced data validation
      const dataValidation = delegateMembers.reduce((acc, member) => {
        if (!member.id) acc.missingIds.push(member);
        if (!member.mrName) acc.missingNames.push(member);
        if (!member.cotisation) acc.missingCotisations.push(member);
        return acc;
      }, { missingIds: [], missingNames: [], missingCotisations: [] });

      const hasInvalidData = Object.values(dataValidation).some(arr => arr.length > 0);

      if (hasInvalidData) {
        console.warn("Données manquantes:", dataValidation);
        toast({
          title: "Attention",
          description: `Certaines données sont manquantes:
            ${dataValidation.missingIds.length ? "\n- Numéros d'adhérent" : ""}
            ${dataValidation.missingNames.length ? "\n- Noms" : ""}
            ${dataValidation.missingCotisations.length ? "\n- Cotisations" : ""}`,
          status: "warning",
          duration: 7000,
          isClosable: true,
        });
      }

      // Prepare data with proper formatting and data validation
      const excelData = [
        ["Liste des adhérents - " + selectedDelegate],
        ["Date d'export: " + new Date().toLocaleDateString()],
        ["Nombre d'adhérents: " + delegateMembers.length],
        [], // Empty row for spacing
        ["Numéro adhérent", "Nom et Prénom", "Cotisation (€)"],
        // Member data with validation
        ...delegateMembers.map((member) => [
          member.id || 'N/A',
          member.mrName || 'N/A',
          member.cotisation ? parseFloat(member.cotisation).toFixed(2) : '0.00'
        ]),
        [], // Empty row before total
        ["", "TOTAL", totalDelegateCotisation.toFixed(2)]
      ];

      // Create worksheet from data
      const worksheet = XLSX.utils.aoa_to_sheet(excelData);

      // Define styles
      const styles = {
        title: {
          font: { bold: true, size: 16 },
          alignment: { horizontal: "center", vertical: "center" },
          fill: { fgColor: { rgb: "E2F0D9" } }
        },
        header: {
          font: { bold: true, size: 12 },
          alignment: { horizontal: "center", vertical: "center" },
          fill: { fgColor: { rgb: "E2F0D9" } },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" }
          }
        },
        info: {
          font: { size: 11 }
        },
        total: {
          font: { bold: true, size: 12 },
          fill: { fgColor: { rgb: "E2F0D9" } },
          border: {
            top: { style: "double" },
            bottom: { style: "double" }
          }
        }
      };

      // Initialize cell styles if they don't exist
      worksheet['!styles'] = {};

      // Apply styles safely
      const applyStyle = (cell, style) => {
        if (worksheet[cell]) {
          worksheet[cell].s = style;
        }
      };

      // Apply styles to different regions
      // Title row (row 1)
      ['A1', 'B1', 'C1'].forEach(cell => applyStyle(cell, styles.title));

      // Info rows (rows 2-3)
      ['A2', 'B2', 'C2', 'A3', 'B3', 'C3'].forEach(cell => applyStyle(cell, styles.info));

      // Header row (row 5)
      ['A5', 'B5', 'C5'].forEach(cell => applyStyle(cell, styles.header));

      // Total row (last row)
      const lastRow = excelData.length;
      [`A${lastRow}`, `B${lastRow}`, `C${lastRow}`].forEach(cell => applyStyle(cell, styles.total));

      // Set column widths
      worksheet['!cols'] = [
        { wch: 15 }, // A
        { wch: 30 }, // B
        { wch: 15 }  // C
      ];

      // Set merged cells
      worksheet['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // Title
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } }, // Date
        { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } }  // Count
      ];

      const workbook = {
        Sheets: { [`Liste ${selectedDelegate}`]: worksheet },
        SheetNames: [`Liste ${selectedDelegate}`]
      };

      // Generate file with custom name
      const date = new Date().toISOString().split('T')[0];
      const sanitizedDelegateName = selectedDelegate
        .replace(/[^a-z0-9]/gi, '_')
        .toLowerCase();
      const fileName = `liste_${sanitizedDelegateName}_${date}.xlsx`;

      // Add artificial delay for better UX
      await new Promise(resolve => setTimeout(resolve, 800));

      const excelBuffer = XLSX.write(workbook, { 
        bookType: "xlsx", 
        type: "array",
        bookSST: false,
        compression: true
      });
      
      const blob = new Blob([excelBuffer], { 
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
      });
      
      saveAs(blob, fileName);

      toast({
        title: "Succès",
        description: `Liste exportée pour le délégué ${selectedDelegate} (${delegateMembers.length} adhérents)`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de l'exportation:", error);
      toast({
        title: "Erreur",
        description: error.message || "Impossible d'exporter les données.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsExporting(false);
    }
  };

  const handleDelete = (member) => {
    setMemberToDelete(member);
    onDeleteDialogOpen();
  };

  const confirmDelete = async () => {
    try {
      await deleteMember(memberToDelete.id);
      toast({
        title: "Supprimé",
        description: "Membre supprimé avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchMembers();
    } catch (error) {
      console.error("Erreur lors de la suppression:", error);
      toast({
        title: "Erreur",
        description: "Impossible de supprimer le membre.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      onDeleteDialogClose();
    }
  };

  const deleteMember = async (memberId) => {
    const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${memberId}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error("Erreur lors de la suppression du membre");
    }
  };

  // Nouvelle fonction pour gérer la sélection de toutes les cases
  const handleSelectAllChange = async (isChecked) => {
    if (!user || !user.profileData) {
      toast({
        title: "Erreur",
        description: "Vous devez être connecté pour effectuer cette action.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const newStatus = isChecked ? "À jour" : "En retard";
    setIsLoading(true);
    try {
      await Promise.all(
        currentMembers.map(async (member) => {
          await updateMemberStatus(member.id, newStatus);
          await fetch(`https://app.falconmarketing.fr:3004/cotisations`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              memberId: member.id,
              status: newStatus,
              responsableId: user.uid,
              responsableNom: user.profileData.nom || '',
              responsablePrenom: user.profileData.prenom || '',
              dateEncaissement: new Date().toISOString()
            }),
          });
        })
      );
      
      toast({
        title: "Succès",
        description: `Statut de cotisation mis à jour pour ${isChecked ? "tous les membres" : "aucun des membres"} affichés.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchMembers();
    } catch (error) {
      console.error("Erreur lors de la mise à jour des statuts de cotisation:", error);
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour les statuts de cotisation pour tous les membres.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderPaginationButtons = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) pageNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((number, index) => {
      if (number === "...") {
        return <Text key={`ellipsis-${index}`}>...</Text>;
      }
      return (
        <Button
          key={number}
          onClick={() => paginate(number)}
          variant={currentPage === number ? "solid" : "ghost"}
          colorScheme="teal"
          size="sm"
        >
          {number}
        </Button>
      );
    });
  };

  return (
    <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
      <ScaleFade initialScale={0.9} in={true}>
      <Box 
        p={4} 
        bg={bgColor} 
          borderRadius="xl"
          boxShadow="xl"
        borderColor={borderColor}
        color={textColor}
          transition="all 0.2s"
          _hover={{ boxShadow: "2xl" }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={6}
          flexDirection={{ base: "column", md: "row" }}
        >
            <SlideFade in={true} offsetY="20px">
              <Heading size="lg" mb={{ base: 4, md: 0 }}>
                Liste des adhérents
              </Heading>
            </SlideFade>
            <Stack 
              direction={{ base: "column", md: "row" }} 
              spacing={4}
              w={{ base: "100%", md: "auto" }}
            >
            <Button
              leftIcon={<MdAdd />}
              colorScheme="teal"
              variant="solid"
              onClick={handleOpenAddMemberModal}
              size="sm"
                transition="all 0.2s"
                _hover={{ transform: "translateY(-2px)", shadow: "lg" }}
            >
              Ajouter un membre
            </Button>
            <Tooltip
              label={
                !selectedDelegate
                  ? "Sélectionnez un délégué pour exporter sa liste"
                  : `Exporter la liste de ${selectedDelegate} (${delegateMembers.length} adhérents)`
              }
              hasArrow
              placement="top"
            >
              <Button
                leftIcon={isExporting ? <Spinner size="sm" /> : <MdEdit />}
                colorScheme={selectedDelegate ? "green" : "gray"}
                variant="outline"
                onClick={exportToExcel}
                size="sm"
                transition="all 0.2s"
                _hover={{ 
                  transform: selectedDelegate ? "translateY(-2px)" : "none",
                  shadow: selectedDelegate ? "lg" : "none"
                }}
                isDisabled={!selectedDelegate || isExporting}
                loadingText="Export en cours..."
                isLoading={isExporting}
              >
                {isExporting 
                  ? "Export en cours..." 
                  : `Exporter la liste${selectedDelegate ? ` (${delegateMembers.length})` : ''}`}
              </Button>
            </Tooltip>
          </Stack>
        </Flex>

          <Fade in={true}>
        <Box mb={4}>
              <SimpleGrid 
                columns={{ base: 1, md: 4 }} 
                spacing={4}
                p={4}
                bg={useColorModeValue("gray.50", "gray.700")}
                borderRadius="lg"
                shadow="sm"
              >
                <StatBox
                  label="Total des adhérents"
                  value={filteredMembers.length}
                  color="blue"
                />
                <StatBox
                  label="À jour"
                  value={filteredMembers.filter((member) => member.contributionStatus === "À jour").length}
                  color="green"
                />
                <StatBox
                  label="En retard"
                  value={filteredMembers.filter((member) => member.contributionStatus === "En retard").length}
                  color="orange"
                />
                <StatBox
                  label="Radiés"
                  value={filteredMembers.filter((member) => member.contributionStatus === "Radié").length}
                  color="red"
                />
          </SimpleGrid>
        </Box>
          </Fade>

          <Stack 
            direction={{ base: "column", md: "row" }} 
            spacing={4} 
            mb={4}
            bg={useColorModeValue("white", "gray.800")}
            p={4}
            borderRadius="lg"
            shadow="sm"
          >
            <FilterSelect
              label="Filtre par délégué"
              value={selectedDelegate}
              onChange={(e) => {
                setSelectedDelegate(e.target.value);
                setCurrentPage(1);
              }}
              options={[
                { value: "", label: "Tous les délégués" },
                ...delegates.sort().map(delegate => ({
                  value: delegate,
                  label: delegate
                }))
              ]}
              inputBg={inputBgColor}
              textColor={textColor}
            />
            <FilterSelect
              label="Filtre par statut"
              value={selectedContributionStatus}
              onChange={(e) => {
                setSelectedContributionStatus(e.target.value);
                setCurrentPage(1);
              }}
              options={[
                { value: "", label: "Tous les statuts" },
                { value: "En attente", label: "En attente" },
                { value: "À jour", label: "À jour" },
                { value: "En retard N-1", label: "En retard N-1" },
                { value: "En retard N-2", label: "En retard N-2" },
                { value: "En retard N-3", label: "En retard N-3" },
                { value: "En retard +N-3", label: "En retard +N-3" },
                { value: "Radié", label: "Radié" }
              ]}
              inputBg={inputBgColor}
              textColor={textColor}
            />
            <FilterSelect
              label="Adhérents par page"
              value={membersPerPage}
              onChange={(e) => setMembersPerPage(Number(e.target.value))}
              options={[
                { value: 10, label: "10" },
                { value: 25, label: "25" },
                { value: 50, label: "50" },
                { value: 100, label: "100" }
              ]}
              inputBg={inputBgColor}
              textColor={textColor}
            />
        </Stack>

        <Box 
          mb={4}
          bg={useColorModeValue("white", "gray.800")}
          p={4}
          borderRadius="lg"
          shadow="sm"
        >
          <SearchBox
            value={searchValue}
            onChange={handleSearchChange}
            inputBg={inputBgColor}
            textColor={textColor}
            isSearching={isSearching}
          />
        </Box>

        <Box overflowX="auto" width="100%">
          {isLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" />
            </Flex>
          ) : displayMode === "desktop" ? (
            <MemberTable
              members={currentMembers}
              handleOpenModal={handleOpenModal}
              handleOpenEditMemberModal={handleOpenEditMemberModal}
              handleCheckboxChange={handleCheckboxChange}
              handleSelectAllChange={handleSelectAllChange}
              getStatusColor={getStatusColor}
              handleDelete={handleDelete}
              requestSort={requestSort}
              sortConfig={sortConfig}
              loadingMembers={loadingMembers}
              textColor={textColor}
              borderColor={borderColor}
              tableHeaderBgColor={tableHeaderBgColor}
              tableRowHoverBg={tableRowHoverBg}
              inputBgColor={inputBgColor}
              tableBgColor={tableBgColor}
              bgColor={bgColor}
              statusColorMapping={statusColorMapping}
              isLoading={isLoading}
            />
          ) : (
            <Stack spacing={4}>
              {currentMembers.map((member, index) => (
                <SlideFade in={true} offsetY={20} delay={index * 0.05} key={member.id}>
                  <MemberCard
                    member={member}
                    handleOpenModal={handleOpenModal}
                    handleOpenEditMemberModal={handleOpenEditMemberModal}
                    handleCheckboxChange={handleCheckboxChange}
                    getStatusColor={getStatusColor}
                    handleDelete={handleDelete}
                    loadingMembers={loadingMembers}
                    textColor={textColor}
                    borderColor={borderColor}
                    bgColor={tableBgColor}
                    inputBgColor={inputBgColor}
                    statusColorMapping={statusColorMapping}
                  />
                </SlideFade>
              ))}
            </Stack>
          )}
        </Box>

        <Flex justifyContent="center" mt={8}>
          <HStack spacing={2} flexWrap="wrap">
            <Button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              variant="ghost"
              colorScheme="teal"
              size="sm"
            >
              Précédent
            </Button>
            {renderPaginationButtons().map((button, index) => (
              <Box key={index}>{button}</Box>
            ))}
            <Button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              variant="ghost"
              colorScheme="teal"
              size="sm"
            >
              Suivant
            </Button>
          </HStack>
        </Flex>

        <AddMemberModal
          isOpen={isAddMemberModalOpen}
          onClose={handleCloseAddMemberModal}
          onSave={handleSaveMember}
          selectedAdherent={selectedAdherent}
            size={{ base: "full", md: "md" }}
        />
        {selectedAdherent && (
          <AdherentDetailsModal
            isOpen={isOpen}
            onClose={onClose}
            adherent={selectedAdherent}
              size={{ base: "full", md: "lg" }}
          />
        )}
        <EditMemberModal
          isOpen={isEditMemberModalOpen}
          onClose={() => setIsEditMemberModalOpen(false)}
          adherent={selectedAdherent}
          onSave={handleSaveMember}
            size={{ base: "full", md: "md" }}
        />

        <AlertDialog
          isOpen={isDeleteDialogOpen}
          leastDestructiveRef={cancelRef}
          onClose={onDeleteDialogClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>Confirmer la suppression</AlertDialogHeader>
              <AlertDialogBody>
                Êtes-vous sûr de vouloir supprimer ce membre ? Cette action est irréversible.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDeleteDialogClose}>
                  Annuler
                </Button>
                <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                  Supprimer
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
      </ScaleFade>
    </Box>
  );
};

const MemberTable = React.memo(({
  members,
  handleOpenModal,
  handleOpenEditMemberModal,
  handleCheckboxChange,
  handleSelectAllChange,
  getStatusColor,
  handleDelete,
  requestSort,
  sortConfig,
  loadingMembers,
  textColor,
  borderColor,
  tableHeaderBgColor,
  tableRowHoverBg,
  inputBgColor,
  tableBgColor,
  bgColor,
  statusColorMapping,
  isLoading
}) => {
  const hoverBg = useColorModeValue("gray.100", "gray.600");

  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === "ascending" ? (
        <TriangleUpIcon color="teal.500" />
      ) : (
        <TriangleDownIcon color="teal.500" />
      );
    }
    return null;
  };

  const isAllSelected = members.every((member) => member.contributionStatus === "À jour");
  const isIndeterminate = members.some((member) => member.contributionStatus === "À jour") && !isAllSelected;

  const tableHeaderStyle = {
    bg: tableHeaderBgColor,
    color: textColor,
    borderColor: borderColor,
    fontSize: "sm",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "wider",
    cursor: "pointer",
    transition: "all 0.2s",
    _hover: {
      bg: hoverBg,
    },
  };

  return (
    <Box 
      width="100%"
      borderRadius="xl"
      overflow="auto"
      shadow="lg"
    >
      <TableContainer isLoading={isLoading}>
        <Table variant="simple" size="sm">
          <Thead position="sticky" top={0} zIndex={1} bg={tableHeaderBgColor}>
            <Tr>
              <Th 
                onClick={() => requestSort("id")} 
              {...tableHeaderStyle}
              borderTopLeftRadius="xl"
              width="60px"
              textAlign="center"
            >
              <HStack spacing={1} justifyContent="center">
                <Text>N°</Text>
                {getSortIcon("id")}
              </HStack>
              </Th>
            <Th 
              onClick={() => requestSort("mrName")} 
              {...tableHeaderStyle}
              width="200px"
            >
              <HStack spacing={1}>
                <Text>Nom et Prénom</Text>
                {getSortIcon("mrName")}
              </HStack>
              </Th>
            <Th {...tableHeaderStyle} width="200px">Adresse</Th>
            <Th {...tableHeaderStyle} width="120px">Téléphone</Th>
            <Th {...tableHeaderStyle} width="120px">Numéro SS</Th>
            <Th {...tableHeaderStyle} width="120px">Délégué</Th>
            <Th {...tableHeaderStyle} width="80px" textAlign="center">Cotisation</Th>
            <Th 
              onClick={() => requestSort("contributionStatus")} 
              {...tableHeaderStyle}
              width="100px"
              textAlign="center"
            >
              <HStack spacing={1} justifyContent="center">
                <Text>Statut</Text>
                {getSortIcon("contributionStatus")}
              </HStack>
              </Th>
            <Th {...tableHeaderStyle} width="120px" textAlign="center">Actions</Th>
            <Th 
              {...tableHeaderStyle}
              borderTopRightRadius="xl"
              width="60px"
              textAlign="center"
            >
                <Checkbox
                  isChecked={isAllSelected}
                  isIndeterminate={isIndeterminate}
                  onChange={(e) => handleSelectAllChange(e.target.checked)}
                  colorScheme="teal"
                size="lg"
                transition="all 0.2s"
                _hover={{
                  transform: "scale(1.1)"
                }}
                />
              </Th>
            </Tr>
          </Thead>
          <AnimatedTbody>
            {members.map((member, index) => (
              <MemberRow
                key={`${member.id}-${index}`}
                member={member}
                handleOpenModal={handleOpenModal}
                handleOpenEditMemberModal={handleOpenEditMemberModal}
                handleCheckboxChange={handleCheckboxChange}
                handleDelete={handleDelete}
                loadingMembers={loadingMembers}
                textColor={textColor}
                borderColor={borderColor}
                hoverBg={tableRowHoverBg}
                inputBgColor={inputBgColor}
                statusColorMapping={statusColorMapping}
                index={index}
              />
            ))}
          </AnimatedTbody>
        </Table>
      </TableContainer>
      </Box>
  );
});

const MemberRow = React.memo(({
  member,
  handleOpenModal,
  handleOpenEditMemberModal,
  handleCheckboxChange,
  handleDelete,
  loadingMembers,
  textColor,
  borderColor,
  hoverBg,
  inputBgColor,
  statusColorMapping,
  index,
}) => {
  const [isEditingCotisation, setIsEditingCotisation] = useState(false);
  const [isUpdatingCotisation, setIsUpdatingCotisation] = useState(false);
  const [cotisationValue, setCotisationValue] = useState(member.cotisation || '0');

  // Déplacer la fonction ici
  const getDetailedStatus = () => {
    if (member.contributionStatus !== "En retard" || !member.lastPaymentYear) {
      return member.contributionStatus;
    }

    const currentYear = new Date().getFullYear();
    const yearsInArrears = currentYear - member.lastPaymentYear;

    switch (yearsInArrears) {
      case 1:
        return "En retard N-1";
      case 2:
        return "En retard N-2";
      case 3:
        return "En retard N-3";
      default:
        return yearsInArrears > 3 ? "En retard +N-3" : member.contributionStatus;
    }
  };

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const toast = useToast();

  // Déplacer tous les hooks au début du composant
  const greenBg = useColorModeValue("green.50", "green.900");
  const greenHoverBg = useColorModeValue("green.100", "green.800");
  const orangeBg = useColorModeValue("orange.50", "orange.900");
  const orangeHoverBg = useColorModeValue("orange.100", "orange.800");
  const redBg = useColorModeValue("red.50", "red.900");
  const redHoverBg = useColorModeValue("red.100", "red.800");
  const avatarBg = useColorModeValue("teal.500", "teal.200");
  const avatarColor = useColorModeValue("white", "gray.800");
  const secondaryTextColor = useColorModeValue("gray.600", "gray.400");

  const getRowBgColor = () => {
    const status = member.contributionStatus;
    switch(status) {
      case "À jour":
        return {
          bg: greenBg,
          _hover: { bg: greenHoverBg }
        };
      case "En retard":
        return {
          bg: orangeBg,
          _hover: { bg: orangeHoverBg }
        };
      case "Radié":
        return {
          bg: redBg,
          _hover: { bg: redHoverBg }
        };
      default:
        return {
          bg: 'transparent',
          _hover: { bg: hoverBg }
        };
    }
  };

  const rowStyles = getRowBgColor();
  const currentStatusStyle = statusColorMapping[member.contributionStatus] || {
    bg: 'transparent',
    color: textColor,
    icon: '',
    hoverBg: 'transparent'
  };

  const handleCotisationClick = () => {
    setIsEditingCotisation(true);
  };

  const handleCotisationChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setCotisationValue(value);
    }
  };

  const handleCotisationBlur = async () => {
    if (cotisationValue !== member.cotisation) {
      setIsUpdatingCotisation(true);
      try {
        const response = await fetch(
          `https://app.falconmarketing.fr:3004/adherents/${member.id}/cotisation`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cotisation: parseFloat(cotisationValue) }),
          }
        );

        if (!response.ok) {
          throw new Error("Erreur lors de la mise à jour de la cotisation");
        }
        toast({
          title: "Succès",
          description: "Cotisation mise �� jour avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Erreur:", error);
        toast({
          title: "Erreur",
          description: "Impossible de mettre à jour la cotisation.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setCotisationValue(member.cotisation);
      } finally {
        setIsUpdatingCotisation(false);
      }
    }
    setIsEditingCotisation(false);
  };

  const handleCheckboxClick = (e) => {
    e.preventDefault();
    if (member.contributionStatus !== "À jour") {
      setIsPaymentModalOpen(true);
    } else {
      handleCheckboxChange(member.id, false);
    }
  };

  const handlePaymentConfirm = async (paymentDetails) => {
    try {
      handleCheckboxChange(member.id, true);
      setIsPaymentModalOpen(false);
    } catch (error) {
      console.error("Erreur:", error);
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour le statut.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <AnimatedTr 
      borderColor={borderColor}
      {...rowStyles}
        transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
        _hover={{
          transform: "scale(1.01)",
          shadow: "sm",
          ...rowStyles._hover
        }}
        delay={`${index * 0.05}s`}
      >
        <Td textAlign="center" width="60px">
          <Text fontWeight="medium">#{member.id}</Text>
        </Td>
        <Td width="200px">
          <HStack spacing={3}>
            <Avatar 
              size="sm" 
              name={member.mrName}
              bg={avatarBg}
              color={avatarColor}
            />
            <Box>
              <Text fontWeight="medium">{member.mrName}</Text>
              {member.mrsName && (
                <Text fontSize="sm" color={secondaryTextColor}>
                  {member.mrsName}
                </Text>
              )}
            </Box>
          </HStack>
        </Td>
        <Td width="200px">
          <Text noOfLines={2}>{member.address}</Text>
        </Td>
        <Td width="120px">
          <Text>{member.phone}</Text>
        </Td>
        <Td width="120px">
          <Text noOfLines={1}>{member.socialSecurityNumber}</Text>
        </Td>
        <Td width="120px">
          <Badge
            colorScheme="purple"
            variant="subtle"
            px={2}
            py={1}
            borderRadius="full"
          >
            {member.delegateName}
          </Badge>
        </Td>
        <Td width="80px" textAlign="center">
        {isUpdatingCotisation ? (
          <Spinner size="sm" />
        ) : isEditingCotisation ? (
          <Input
            size="sm"
            value={cotisationValue}
            onChange={handleCotisationChange}
            onBlur={handleCotisationBlur}
            bg={inputBgColor}
            color={textColor}
            borderColor={borderColor}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleCotisationBlur();
              }
            }}
            autoFocus
              width="70px"
              textAlign="center"
          />
        ) : (
          <Text
            cursor="pointer"
            onClick={handleCotisationClick}
            _hover={{ textDecoration: "underline" }}
            color={textColor}
              fontWeight="medium"
          >
              {member.cotisation || '0'} €
          </Text>
        )}
      </Td>
        <Td width="100px" textAlign="center">
          <Badge
            {...getStatusBadgeProps(getDetailedStatus())}
            px={2}
            py={1}
          >
            {getDetailedStatus()}
          </Badge>
      </Td>
        <Td width="120px" textAlign="center">
          <HStack spacing={2} justifyContent="center">
            <Tooltip 
              label="Voir les détails complets de l'adhérent" 
              placement="top"
              hasArrow
              openDelay={400}
            >
              <IconButton
                aria-label="Voir les détails"
                icon={<MdRemoveRedEye />}
                variant="ghost"
                size="sm"
                colorScheme="blue"
                onClick={() => handleOpenModal(member)}
                transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
                _hover={{
                  transform: "translateY(-2px) scale(1.1)",
                  shadow: "lg",
                  bg: "blue.50"
                }}
                _active={{
                  transform: "scale(0.95)",
                }}
              />
            </Tooltip>
            <Tooltip 
              label="Modifier les informations de l'adhérent" 
              placement="top"
              hasArrow
              openDelay={400}
            >
              <IconButton
                aria-label="Modifier"
                icon={<MdEdit />}
                variant="ghost"
                size="sm"
                colorScheme="green"
                onClick={() => handleOpenEditMemberModal(member)}
                transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
                _hover={{
                  transform: "translateY(-2px) scale(1.1)",
                  shadow: "lg",
                  bg: "green.50"
                }}
                _active={{
                  transform: "scale(0.95)",
                }}
              />
            </Tooltip>
            <Tooltip 
              label="Supprimer définitivement l'adhérent" 
              placement="top"
              hasArrow
              openDelay={400}
            >
              <IconButton
                aria-label="Supprimer"
                icon={<MdDelete />}
                variant="ghost"
                size="sm"
                colorScheme="red"
                onClick={() => handleDelete(member)}
                transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
                _hover={{
                  transform: "translateY(-2px) scale(1.1)",
                  shadow: "lg",
                  bg: "red.50"
                }}
                _active={{
                  transform: "scale(0.95)",
                }}
              />
            </Tooltip>
          </HStack>
        </Td>
        <Td width="60px" textAlign="center">
          <Flex alignItems="center" justifyContent="center">
            {loadingMembers[member.id] ? (
              <Spinner size="sm" color="teal.500" />
            ) : (
              <Tooltip 
                label={member.contributionStatus === "À jour" ? 
                  "Cliquez pour marquer comme non payé" : 
                  "Cliquez pour enregistrer le paiement"}
                placement="top"
                hasArrow
              >
                <Box position="relative">
                  <Checkbox
                    isChecked={member.contributionStatus === "À jour"}
                    onChange={handleCheckboxClick}
                    colorScheme="teal"
                    size="lg"
                    transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
                    sx={{
                      '& input:checked + span': {
                        animation: `${glow} 1.5s infinite`
                      },
                      '&:hover': {
                        transform: "scale(1.1)",
                        '& span': {
                          bg: "teal.50"
                        }
                      },
                      '&::after': {
                        content: '""',
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        bg: "teal.500",
                        transform: "translate(-50%, -50%)",
                        opacity: 0,
                        pointerEvents: "none"
                      },
                      '&:active::after': {
                        animation: `${ripple} 0.6s ease-out`
                      }
                    }}
                  />
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Td>
    </AnimatedTr>
      <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        member={member}
        onConfirm={handlePaymentConfirm}
        isLoading={loadingMembers[member.id]}
      />
    </>
  );
});

const MemberCard = React.memo(({
  member,
  handleOpenModal,
  handleOpenEditMemberModal,
  handleCheckboxChange,
  getStatusColor,
  handleDelete,
  loadingMembers,
  textColor,
  borderColor,
  bgColor,
  inputBgColor,
  statusColorMapping,
}) => {
  const [isEditingCotisation, setIsEditingCotisation] = useState(false);
  const [cotisationValue, setCotisationValue] = useState(member.cotisation || '');
  const [isUpdatingCotisation, setIsUpdatingCotisation] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const toast = useToast();

  const avatarBg = useColorModeValue("teal.500", "teal.200");
  const avatarColor = useColorModeValue("white", "gray.800");
  const secondaryTextColor = useColorModeValue("gray.600", "gray.400");
  const cardBg = useColorModeValue("white", "gray.800");

  const currentStatusStyle = statusColorMapping[member.contributionStatus] || {
    bg: 'transparent',
    color: textColor,
    icon: '',
    hoverBg: 'transparent'
  };

  const handleCotisationClick = () => {
    setIsEditingCotisation(true);
  };

  const handleCotisationChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setCotisationValue(value);
    }
  };

  const handleCotisationBlur = async () => {
    if (cotisationValue !== member.cotisation) {
      setIsUpdatingCotisation(true);
      try {
        const response = await fetch(
          `https://app.falconmarketing.fr:3004/adherents/${member.id}/cotisation`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cotisation: parseFloat(cotisationValue) }),
          }
        );

        if (!response.ok) {
          throw new Error("Erreur lors de la mise à jour de la cotisation");
        }
        toast({
          title: "Succès",
          description: "Cotisation mise à jour avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Erreur:", error);
        toast({
          title: "Erreur",
          description: "Impossible de mettre à jour la cotisation.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setCotisationValue(member.cotisation);
      } finally {
        setIsUpdatingCotisation(false);
      }
    }
    setIsEditingCotisation(false);
  };

  const handleCheckboxClick = (e) => {
    e.preventDefault();
    if (member.contributionStatus !== "À jour") {
      setIsPaymentModalOpen(true);
    } else {
      handleCheckboxChange(member.id, false);
    }
  };

  const handlePaymentConfirm = async (paymentDetails) => {
    try {
      handleCheckboxChange(member.id, true);
      setIsPaymentModalOpen(false);
    } catch (error) {
      console.error("Erreur:", error);
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour le statut.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <AnimatedBox
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
        p={3}
        mb={3}
      borderColor={borderColor}
        bg={cardBg}
        shadow="sm"
        transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
        position="relative"
        _hover={{
          transform: "translateY(-4px) scale(1.01)",
          shadow: "xl",
          borderColor: "teal.200",
          _after: {
            opacity: 1,
          }
        }}
        _after={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: "lg",
          transition: "opacity 0.4s",
          opacity: 0,
          bg: "linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent)",
          animation: `${shimmer} 2s infinite linear`,
          pointerEvents: "none",
        }}
        sx={{
          "@media (hover: hover)": {
            "&:hover": {
              "& > *": {
                transform: "translateZ(0)",
              }
            }
          }
        }}
      >
        <AnimatedFlex 
          justifyContent="space-between" 
          alignItems="flex-start"
          mb={3}
        >
          <HStack spacing={2} flex={1}>
            <Avatar 
              name={member.mrName} 
              size="sm"
              bg={avatarBg}
              color={avatarColor}
              transition="all 0.3s"
              _hover={{
                transform: "scale(1.1) rotate(5deg)",
                animation: `${float} 2s ease-in-out infinite`
              }}
            />
        <Box>
              <Text 
                fontWeight="bold" 
                fontSize="md" 
                noOfLines={1}
                transition="all 0.3s"
                _hover={{ color: "teal.400" }}
              >
                #{member.id} - {member.mrName}
              </Text>
              {member.mrsName && (
                <Text 
                  fontSize="sm" 
                  color={secondaryTextColor} 
                  noOfLines={1}
                  transition="all 0.3s"
                  _hover={{ color: "teal.300" }}
                >
                  {member.mrsName}
                </Text>
              )}
            </Box>
          </HStack>
          <AnimatedBadge
            bg={currentStatusStyle.bg}
            color={currentStatusStyle.color}
            px={2}
            py={1}
            borderRadius="full"
            fontSize="xs"
            fontWeight="medium"
            display="flex"
            alignItems="center"
            ml={2}
            transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
            _hover={{
              transform: "scale(1.1)",
              shadow: "md",
              bg: currentStatusStyle.hoverBg,
            }}
          >
            <Text mr={1}>{currentStatusStyle.icon}</Text>
            {member.contributionStatus}
          </AnimatedBadge>
        </AnimatedFlex>

        <Stack spacing={2} mb={3}>
          <AnimatedFlex alignItems="center" justifyContent="space-between">
            <Text fontSize="sm" color={secondaryTextColor}>Délégué:</Text>
            <AnimatedBadge
              colorScheme="purple"
              variant="subtle"
              px={2}
              py={0.5}
              borderRadius="full"
              fontSize="xs"
              transition="all 0.3s"
              _hover={{
                transform: "scale(1.05)",
                bg: "purple.100",
                color: "purple.700"
              }}
            >
              {member.delegateName}
            </AnimatedBadge>
          </AnimatedFlex>

          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="sm" color={secondaryTextColor}>Cotisation:</Text>
          {isUpdatingCotisation ? (
              <Spinner 
                size="xs" 
                color="teal.500"
                animation={`${breathe} 1.5s ease-in-out infinite`}
              />
          ) : isEditingCotisation ? (
            <Input
                size="xs"
              value={cotisationValue}
              onChange={handleCotisationChange}
              onBlur={handleCotisationBlur}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleCotisationBlur();
                }
              }}
                width="70px"
              autoFocus
                textAlign="center"
                transition="all 0.3s"
                _focus={{
                  transform: "scale(1.05)",
                  borderColor: "teal.300",
                  boxShadow: "0 0 0 1px teal.300"
                }}
            />
          ) : (
            <Text
                fontSize="sm"
                fontWeight="medium"
              cursor="pointer"
              onClick={handleCotisationClick}
                transition="all 0.3s"
                _hover={{ 
                  color: "teal.400",
                  transform: "scale(1.1)"
                }}
              >
                {member.cotisation || '0'} €
            </Text>
          )}
          </Flex>

          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="sm" color={secondaryTextColor}>Téléphone:</Text>
            <Text fontSize="sm">{member.phone}</Text>
          </Flex>

          <Box>
            <Text fontSize="sm" color={secondaryTextColor} mb={1}>Adresse:</Text>
            <Text fontSize="sm" noOfLines={2}>{member.address}</Text>
        </Box>
      </Stack>

        <Flex 
          justifyContent="space-between" 
          alignItems="center"
          pt={2}
          borderTopWidth="1px"
          borderColor={borderColor}
        >
          <HStack spacing={2} justifyContent="center">
        <Tooltip 
          label="Voir les détails complets de l'adhérent" 
          placement="top"
          hasArrow
          openDelay={400}
        >
          <IconButton
            aria-label="Voir les détails"
            icon={<MdRemoveRedEye />}
            variant="ghost"
            size="sm"
            colorScheme="blue"
            onClick={() => handleOpenModal(member)}
            transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
            _hover={{
              transform: "translateY(-2px) scale(1.1)",
              shadow: "lg",
              bg: "blue.50"
            }}
            _active={{
              transform: "scale(0.95)",
            }}
          />
        </Tooltip>
        <Tooltip 
          label="Modifier les informations de l'adhérent" 
          placement="top"
          hasArrow
          openDelay={400}
        >
          <IconButton
            aria-label="Modifier"
            icon={<MdEdit />}
            variant="ghost"
            size="sm"
            colorScheme="green"
            onClick={() => handleOpenEditMemberModal(member)}
            transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
            _hover={{
              transform: "translateY(-2px) scale(1.1)",
              shadow: "lg",
              bg: "green.50"
            }}
            _active={{
              transform: "scale(0.95)",
            }}
          />
        </Tooltip>
        <Tooltip 
          label="Supprimer définitivement l'adhérent" 
          placement="top"
          hasArrow
          openDelay={400}
        >
          <IconButton
            aria-label="Supprimer"
            icon={<MdDelete />}
            variant="ghost"
            size="sm"
            colorScheme="red"
            onClick={() => handleDelete(member)}
            transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
            _hover={{
              transform: "translateY(-2px) scale(1.1)",
              shadow: "lg",
              bg: "red.50"
            }}
            _active={{
              transform: "scale(0.95)",
            }}
          />
        </Tooltip>
          </HStack>
        </Flex>
      </AnimatedBox>
      <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        member={member}
        onConfirm={handlePaymentConfirm}
        isLoading={loadingMembers[member.id]}
      />
    </>
  );
});

const InfoItem = ({ label, value }) => {
  const valueColor = useColorModeValue("gray.600", "gray.400");

  return (
    <Box>
      <Text fontWeight="medium" mb={1}>{label}:</Text>
      {typeof value === "string" ? (
        <Text color={valueColor}>{value}</Text>
      ) : (
        value
      )}
    </Box>
  );
};

// Nouveaux composants utilitaires
const StatBox = ({ label, value, color }) => {
  const bgColor = useColorModeValue(`${color}.50`, `${color}.900`);
  const textColor = useColorModeValue(`${color}.700`, `${color}.100`);

  return (
    <Box
      p={4}
      borderRadius="lg"
      bg={bgColor}
      color={textColor}
      transition="all 0.2s"
      _hover={{ transform: "translateY(-2px)", shadow: "md" }}
    >
      <Text fontSize="sm" fontWeight="medium">
        {label}
      </Text>
      <Text fontSize="2xl" fontWeight="bold">
        {value}
      </Text>
    </Box>
  );
};

const SearchBox = ({ value, onChange, inputBg, textColor, isSearching }) => {
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const searchHintText = useColorModeValue("gray.600", "gray.400");

  const searchPrefixes = [
    { prefix: 'id:', description: 'Numéro adhérent' },
    { prefix: 'tel:', description: 'Téléphone' },
    { prefix: 'ss:', description: 'Numéro SS' }
  ];

  // Fonction pour extraire la valeur avec préfixe
  const getSearchWithPrefix = (searchText, prefix) => {
    if (!searchText) return '';
    const regex = new RegExp(`${prefix}\\s*([^\\n]*?)(?=\\s+(?:id:|tel:|ss:)|$)`, 'i');
    const match = searchText.match(regex);
    return match ? match[1].trim() : '';
  };

  // Fonction pour gérer le changement des champs de recherche avancée
  const handleAdvancedSearchChange = (prefix, value) => {
    // Si la valeur est vide, on retire le préfixe et sa valeur de la recherche
    if (!value.trim()) {
      const newSearchValue = value.split(' ')
        .filter(term => !term.startsWith(prefix))
        .join(' ')
        .trim();
      onChange({ target: { value: newSearchValue } });
      return;
    }

    const searchValue = value.trim();
    
    // On construit la nouvelle valeur de recherche en préservant les autres préfixes
    const otherPrefixes = searchPrefixes
      .filter(p => p.prefix !== prefix)
      .map(p => {
        const existingValue = getSearchWithPrefix(value, p.prefix);
        return existingValue ? `${p.prefix}${existingValue} ` : '';
      })
      .join('')
      .trim();

    const newValue = `${otherPrefixes ? otherPrefixes + ' ' : ''}${prefix}${searchValue}`;
    onChange({ target: { value: newValue.trim() } });
  };

  return (
    <Box flex="1">
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontSize="sm" fontWeight="medium">
          Recherche :
        </Text>
        <Button
          size="xs"
          variant="ghost"
          rightIcon={showAdvancedSearch ? <TriangleUpIcon /> : <TriangleDownIcon />}
          onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
          _hover={{ color: "teal.500" }}
        >
          Recherche avancée
        </Button>
      </Flex>
      
      <VStack spacing={3} width="100%">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Box position="relative">
              <Icon 
                as={MdSearch} 
                color="gray.500"
                transition="all 0.3s"
                animation={isSearching ? `${float} 1s infinite ease-in-out` : "none"}
              />
              {isSearching && (
                <Box
                  position="absolute"
                  bottom="-2px"
                  left="50%"
                  transform="translateX(-50%)"
                  width="4px"
                  height="4px"
                  borderRadius="full"
                  bg="teal.500"
                  animation={`${pulse} 1s infinite`}
                />
              )}
            </Box>
          </InputLeftElement>
          <Input
            placeholder="Recherche par nom ou utilisez les préfixes (id:, tel:, ss:)"
            value={value}
            onChange={onChange}
            bg={inputBg}
            color={textColor}
            transition="all 0.2s"
            _focus={{
              boxShadow: "0 0 0 2px var(--chakra-colors-teal-500)",
              transform: "translateY(-2px)"
            }}
            sx={{
              "&::placeholder": {
                color: "gray.400",
                transition: "all 0.2s"
              },
              "&:focus::placeholder": {
                opacity: 0.5,
                transform: "translateX(10px)"
              }
            }}
          />
        </InputGroup>

        <Collapse in={showAdvancedSearch} animateOpacity style={{ width: '100%' }}>
          <VStack spacing={4} p={4} bg={useColorModeValue("gray.50", "gray.700")} borderRadius="md" width="100%">
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="100%">
              <FormControl>
                <FormLabel fontSize="sm">Numéro adhérent</FormLabel>
                <Input
                  placeholder="Ex: 12345"
                  size="sm"
                  value={getSearchWithPrefix(value, 'id:')}
                  onChange={(e) => handleAdvancedSearchChange('id:', e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">Téléphone</FormLabel>
                <Input
                  placeholder="Ex: 0612345678"
                  size="sm"
                  value={getSearchWithPrefix(value, 'tel:')}
                  onChange={(e) => handleAdvancedSearchChange('tel:', e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">Numéro SS</FormLabel>
                <Input
                  placeholder="Ex: 1234567890123"
                  size="sm"
                  value={getSearchWithPrefix(value, 'ss:')}
                  onChange={(e) => handleAdvancedSearchChange('ss:', e.target.value)}
                />
              </FormControl>
            </SimpleGrid>
            
            <Box width="100%" pt={2}>
              <Text fontSize="xs" color={searchHintText}>
                Astuce : Vous pouvez aussi utiliser les préfixes directement dans la barre de recherche :
              </Text>
              <Text fontSize="xs" color={searchHintText}>
                {searchPrefixes.map(p => `${p.prefix} (${p.description})`).join(' | ')}
              </Text>
            </Box>
          </VStack>
        </Collapse>
      </VStack>
    </Box>
  );
};

const FilterSelect = ({ label, value, onChange, options, inputBg, textColor }) => (
  <Box flex="1">
    <Text mb={2} fontSize="sm" fontWeight="medium">
      {label}
    </Text>
    <Select
      value={value}
      onChange={onChange}
      bg={inputBg}
      color={textColor}
      transition="all 0.3s"
      cursor="pointer"
      icon={<Icon as={TriangleDownIcon} transition="transform 0.2s" />}
      sx={{
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: "sm"
        },
        "&:focus": {
          boxShadow: "0 0 0 2px var(--chakra-colors-teal-500)",
          transform: "translateY(-2px)"
        },
        "& option": {
          bg: inputBg,
          color: textColor,
          padding: "10px",
          transition: "all 0.2s"
        },
        "& option:hover": {
          bg: "teal.50"
        },
        "_focus": {
          "& > svg": {
            transform: "rotate(180deg)"
          }
        }
      }}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  </Box>
);

// Amélioration de la pagination
const PaginationButton = ({ children, isActive, onClick, isDisabled }) => {
  const activeBg = useColorModeValue("teal.500", "teal.300");
  const activeColor = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("teal.600", "teal.400");
  const normalBg = useColorModeValue("gray.100", "gray.700");
  const normalColor = useColorModeValue("gray.800", "white");

  return (
    <Button
      size="sm"
      variant={isActive ? "solid" : "ghost"}
      bg={isActive ? activeBg : normalBg}
      color={isActive ? activeColor : normalColor}
      onClick={onClick}
      isDisabled={isDisabled}
      _hover={{
        bg: isActive ? hoverBg : normalBg,
        transform: "translateY(-2px)",
        shadow: "md",
      }}
      transition="all 0.2s"
    >
      {children}
    </Button>
  );
};

const renderPaginationButtons = (currentPage, totalPages, paginate) => {
  const pageNumbers = [];
  const maxVisiblePages = 5;
  let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  if (endPage - startPage + 1 < maxVisiblePages) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  if (startPage > 1) {
    pageNumbers.push(1);
    if (startPage > 2) pageNumbers.push("...");
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) pageNumbers.push("...");
    pageNumbers.push(totalPages);
  }

  return (
    <HStack spacing={2} flexWrap="wrap" justify="center">
      <PaginationButton
        onClick={() => paginate(currentPage - 1)}
        isDisabled={currentPage === 1}
      >
        ←
      </PaginationButton>

      {pageNumbers.map((number, index) => {
        if (number === "...") {
          return (
            <Text key={`ellipsis-${index}`} color="gray.500">
              ...
            </Text>
          );
        }
        return (
          <PaginationButton
            key={number}
            isActive={currentPage === number}
            onClick={() => paginate(number)}
          >
            {number}
          </PaginationButton>
        );
      })}

      <PaginationButton
        onClick={() => paginate(currentPage + 1)}
        isDisabled={currentPage === totalPages}
      >
        →
      </PaginationButton>
    </HStack>
  );
};

const PaymentModal = ({
  isOpen,
  onClose,
  member,
  onConfirm,
  isLoading
}) => {
  const [paymentMethod, setPaymentMethod] = useState('especes');
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const footerBgColor = useColorModeValue("gray.50", "gray.900");

  const handleSubmit = () => {
    onConfirm({
      paymentMethod,
      amount: member?.cotisation || 0,
    });
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size={isMobile ? "full" : "sm"}
      motionPreset={isMobile ? "slideInBottom" : "scale"}
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px)"
      />
      <ModalContent 
        margin={isMobile ? 0 : "auto"} 
        roundedTop={isMobile ? 0 : "xl"}
        overflow="hidden"
        bg={bgColor}
        borderColor={borderColor}
        borderWidth="1px"
        shadow="2xl"
        transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
        _hover={{
          transform: "scale(1.01)",
          shadow: "2xl",
        }}
      >
        <ModalHeader 
          fontSize={isMobile ? "xl" : "lg"}
          textAlign={isMobile ? "center" : "left"}
          pt={isMobile ? 6 : 4}
          pb={4}
          borderBottomWidth="1px"
          borderColor={borderColor}
        >
          <AnimatedFlex alignItems="center" justifyContent={isMobile ? "center" : "flex-start"}>
            <Icon 
              as={MdPayment} 
              mr={3} 
              boxSize={6} 
              color="teal.500"
              animation={`${float} 2s ease-in-out infinite`}
            />
            Mode de paiement
          </AnimatedFlex>
        </ModalHeader>
        <ModalCloseButton 
          size={isMobile ? "lg" : "md"}
          top={isMobile ? 4 : 2}
          transition="all 0.2s"
          _hover={{
            transform: "rotate(90deg)",
            bg: "transparent",
            color: "teal.500",
          }}
        />
        <ModalBody py={6}>
          <RadioGroup value={paymentMethod} onChange={setPaymentMethod}>
            <Stack direction="column" spacing={isMobile ? 6 : 4}>
              {[
                { value: 'especes', icon: MdAttachMoney, label: 'Espèces', delay: '0.1s' },
                { value: 'carte', icon: MdCreditCard, label: 'Carte bancaire', delay: '0.2s' },
                { value: 'cheque', icon: MdPayment, label: 'Chèque', delay: '0.3s' }
              ].map((option) => (
                <AnimatedBox 
                  key={option.value}
                  animation={`${slideIn} 0.3s ease-out forwards`} 
                  opacity={0}
                  style={{ animationDelay: option.delay }}
                >
                  <Radio 
                    value={option.value} 
                    size={isMobile ? "lg" : "md"}
                    transition="all 0.3s"
                    _hover={{
                      transform: "translateX(10px)",
                    }}
                  >
                    <HStack 
                      spacing={3} 
                      align="center"
                      transition="all 0.3s"
                      _hover={{ color: "teal.500" }}
                    >
                      <Icon 
                        as={option.icon} 
                        boxSize={isMobile ? 6 : 5}
                        animation={paymentMethod === option.value ? `${float} 2s ease-in-out infinite` : "none"}
                      />
                      <Text fontWeight="medium" fontSize={isMobile ? "lg" : "md"}>
                        {option.label}
                      </Text>
                    </HStack>
                  </Radio>
                </AnimatedBox>
              ))}
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter 
          flexDirection={isMobile ? "column" : "row"} 
          gap={isMobile ? 3 : 0}
          borderTopWidth="1px"
          borderColor={borderColor}
          bg={footerBgColor}
          p={4}
        >
          <Button 
            variant="ghost" 
            mr={isMobile ? 0 : 3}
            w={isMobile ? "full" : "auto"}
            size={isMobile ? "lg" : "md"}
            onClick={onClose}
            transition="all 0.3s"
            _hover={{
              transform: "translateY(-2px)",
              shadow: "md",
              bg: "gray.100"
            }}
          >
            Annuler
          </Button>
          <Button
            colorScheme="teal"
            onClick={handleSubmit}
            isLoading={isLoading}
            w={isMobile ? "full" : "auto"}
            size={isMobile ? "lg" : "md"}
            transition="all 0.3s"
            _hover={{
              transform: "translateY(-2px)",
              shadow: "lg",
            }}
            _active={{
              transform: "scale(0.95)",
            }}
            sx={{
              "&:not(:disabled)": {
                animation: `${pulse} 2s infinite`
              }
            }}
          >
            Confirmer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const getStatusBadgeProps = (status) => {
  // Gérer le cas où le statut est null ou undefined
  if (!status) {
    return {
      colorScheme: "gray",
      variant: "outline"
    };
  }

  if (status === "À jour") {
    return {
      colorScheme: "green",
      variant: "solid"
    };
  }
  
  if (status.startsWith("En retard")) {
    const yearsLate = status.includes("N-1") ? 1 :
                      status.includes("N-2") ? 2 :
                      status.includes("N-3") ? 3 : 4;
    
    return {
      colorScheme: "red",
      variant: "solid",
      opacity: 0.6 + (yearsLate * 0.1)
    };
  }
  
  if (status === "Radié") {
    return {
      colorScheme: "gray",
      variant: "solid"
    };
  }

  if (status === "En attente") {
    return {
      colorScheme: "orange",
      variant: "solid",
      opacity: 0.9
    };
  }
  
  return {
    colorScheme: "yellow",
    variant: "solid"
  };
};

const calculateDetailedStatus = (contributionStatus, lastPaymentYear) => {
  // Gérer le cas où le statut est null ou undefined
  if (!contributionStatus) {
    return "En attente";
  }

  if (contributionStatus !== "En retard" || !lastPaymentYear) {
    return contributionStatus;
  }

  const currentYear = new Date().getFullYear();
  const yearsInArrears = currentYear - lastPaymentYear;

  switch (yearsInArrears) {
    case 1:
      return "En retard N-1";
    case 2:
      return "En retard N-2";
    case 3:
      return "En retard N-3";
    default:
      return yearsInArrears > 3 ? "En retard +N-3" : contributionStatus;
  }
};

export default MemberList;
